var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CSidebar",
    {
      attrs: {
        aside: "",
        show: _vm.$store.state.editTaskAsideShow,
        colorScheme: "light",
        overlaid: "",
        size: "lg",
      },
      on: {
        "update:show": (val) =>
          _vm.$store.commit("set", ["editTaskAsideShow", val]),
      },
    },
    [
      _c("CSidebarClose", {
        nativeOn: {
          click: function ($event) {
            return _vm.$store.commit("toggle", "editTaskAsideShow")
          },
        },
      }),
      _c(
        "CListGroup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showErrorAlert,
              expression: "showErrorAlert",
            },
          ],
          staticClass: "list-group-accent mt-5",
        },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" Error ")]
          ),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-danger list-group-item-divider",
              attrs: { href: "#" },
            },
            [
              _c(
                "div",
                { staticClass: "c-avatar float-left mr-2" },
                [
                  _c("CIcon", {
                    staticClass: "c-avatar-img text-danger",
                    attrs: { name: "cil-bug", size: "xl" },
                  }),
                ],
                1
              ),
              _c("div", [_vm._v(_vm._s(_vm.errorAlertMessage))]),
            ]
          ),
        ],
        1
      ),
      _c(
        "CListGroup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showErrorAlert,
              expression: "!showErrorAlert",
            },
          ],
          staticClass: "scrollable list-group-accent mt-5",
        },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "mb-0 list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" Edit Task ")]
          ),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-divider list-group-item-accent-dark",
            },
            [
              _c(
                "div",
                { staticClass: "form-group mb-0" },
                [
                  _c("label", { staticClass: "d-block" }, [
                    _vm._v("Change Task Status:"),
                  ]),
                  _c("TaskLabel", {
                    attrs: { label: _vm.task.label },
                    on: {
                      "update:label": function ($event) {
                        return _vm.$set(_vm.task, "label", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-divider list-group-item-accent-primary",
            },
            [
              _c(
                "CForm",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.update.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("CInput", {
                    staticClass: "compose-input",
                    attrs: {
                      label: "Subject:",
                      placeholder: "Subject",
                      value: _vm.task.subject,
                      lazy: false,
                      "was-validated": "",
                      required: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.task, "subject", $event)
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "d-block" }, [
                        _vm._v("New Recipients:"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          label: "first_name",
                          placeholder: "Select..",
                          multiple: "",
                          options: _vm.users,
                          loading: _vm.loading,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function (user) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(`${user.first_name}`)),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.recipients,
                          callback: function ($$v) {
                            _vm.recipients = $$v
                          },
                          expression: "recipients",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.recipients.length
                    ? _c(
                        "div",
                        { staticClass: "form-group reply" },
                        [
                          _c("label", { staticClass: "d-block" }, [
                            _vm._v("Message:"),
                          ]),
                          _c("quill-editor", {
                            attrs: { options: _vm.editorOption },
                            model: {
                              value: _vm.email.reply,
                              callback: function ($$v) {
                                _vm.$set(_vm.email, "reply", $$v)
                              },
                              expression: "email.reply",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "mt-2 p-1 bg-danger text-light" },
                            [
                              _c("CInputCheckbox", {
                                attrs: {
                                  custom: "",
                                  label: `Delete the task from ${_vm.task.assigned_name}`,
                                  checked: _vm.deleteSelf,
                                },
                                on: {
                                  "update:checked": function ($event) {
                                    _vm.deleteSelf = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "CButton",
                    {
                      attrs: {
                        type: "submit",
                        color: "primary",
                        disabled: _vm.submitted,
                      },
                    },
                    [
                      _c("CIcon", { attrs: { name: "cil-send" } }),
                      _vm._v(" Submit "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading || _vm.submitted,
            expression: "loading || submitted",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }