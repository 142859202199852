var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CSidebar",
    {
      attrs: {
        aside: "",
        show: _vm.$store.state.taskActivityLogsAsideShow,
        colorScheme: "light",
        overlaid: "",
        size: "lg",
      },
      on: {
        "update:show": (val) =>
          _vm.$store.commit("set", ["taskActivityLogsAsideShow", val]),
      },
    },
    [
      _c("CSidebarClose", {
        nativeOn: {
          click: function ($event) {
            return _vm.$store.commit("toggle", "taskActivityLogsAsideShow")
          },
        },
      }),
      _c(
        "CListGroup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showErrorAlert,
              expression: "showErrorAlert",
            },
          ],
          staticClass: "list-group-accent mt-5",
        },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" Error ")]
          ),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-danger list-group-item-divider",
              attrs: { href: "#" },
            },
            [
              _c(
                "div",
                { staticClass: "c-avatar float-left mr-2" },
                [
                  _c("CIcon", {
                    staticClass: "c-avatar-img text-danger",
                    attrs: { name: "cil-bug", size: "xl" },
                  }),
                ],
                1
              ),
              _c("div", [_vm._v(_vm._s(_vm.errorAlertMessage))]),
            ]
          ),
        ],
        1
      ),
      _c(
        "CListGroup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showErrorAlert,
              expression: "!showErrorAlert",
            },
          ],
          staticClass: "scrollable list-group-accent mt-5",
        },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "mb-0 list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" Change Task Status ")]
          ),
          _c(
            "CListGroupItem",
            { staticClass: "list-group-item-accent-dark" },
            [
              _c("TaskLabel", {
                attrs: { label: _vm.task.label },
                on: {
                  "update:label": function ($event) {
                    return _vm.$set(_vm.task, "label", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" Activity Logs ")]
          ),
          _vm._l(_vm.logs, function (activity) {
            return [
              _c(
                "CListGroupItem",
                {
                  staticClass: "list-group-item-divider",
                  class: `list-group-item-accent-${activity.new_label?.color}`,
                },
                [
                  _c("div", [_vm._v(_vm._s(activity?.description))]),
                  _c(
                    "div",
                    { staticClass: "small text-muted mt-1" },
                    [
                      _c("CIcon", {
                        staticClass: "mr-2",
                        attrs: { name: "cil-tag" },
                      }),
                      _vm._v(" " + _vm._s(activity.old_label?.label) + " "),
                      _c("CIcon", {
                        staticClass: "mx-2",
                        attrs: { name: "cil-arrow-right" },
                      }),
                      _vm._v(" " + _vm._s(activity.new_label?.label) + " "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "small text-muted mt-1" },
                    [
                      _c("CIcon", {
                        staticClass: "mr-2",
                        attrs: { name: "cil-calendar" },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.moment(activity.created_at).calendar()) +
                          " "
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading || _vm.submitted,
            expression: "loading || submitted",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }