var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.allTasks.length > 0
        ? _c("CDataTable", {
            staticClass: "tasks-table table-outline",
            attrs: {
              hover: "",
              items: _vm.allTasks,
              fields: _vm.fields,
              loading: _vm.loading,
              noItemsView: {
                noResults: "No filtering results available!",
                noItems: "No task found!",
              },
              "head-color": "light",
              "no-sorting": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "assigned_by",
                  fn: function ({ item }) {
                    return _c("td", {}, [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip",
                                value: { content: "#" + item.from.id },
                                expression: "{ content: '#' + item.from.id }",
                              },
                            ],
                            staticClass: "c-avatar mr-2",
                          },
                          [
                            _c("img", {
                              staticClass: "c-avatar-img",
                              attrs: {
                                src: item.from.image_icon || item.from.gravatar,
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _c("div", [
                          _c("span", [_vm._v(_vm._s(item.from.first_name))]),
                          _c(
                            "span",
                            { staticClass: "d-block small text-muted" },
                            [
                              _vm._v(
                                _vm._s(_vm.moment(item.created_at).calendar())
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  },
                },
                {
                  key: "title",
                  fn: function ({ item }) {
                    return _c("td", {}, [
                      _c(
                        "p",
                        { staticClass: "h6" },
                        [
                          _vm._v(" " + _vm._s(item.subject) + " "),
                          _c(
                            "CLink",
                            {
                              directives: [
                                {
                                  name: "c-tooltip",
                                  rawName: "v-c-tooltip",
                                  value: { content: "Show Thread" },
                                  expression: "{content : 'Show Thread'}",
                                },
                              ],
                              attrs: {
                                to: {
                                  name: "Message",
                                  params: { id: item.task_id, folder: "inbox" },
                                  query: { user: item.user_id },
                                },
                              },
                            },
                            [_c("CIcon", { attrs: { name: "cil-at" } })],
                            1
                          ),
                        ],
                        1
                      ),
                      item.restaurant
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-restaurant" },
                              }),
                              _c(
                                "CLink",
                                {
                                  staticClass: "ml-1 small text-muted",
                                  attrs: {
                                    to: {
                                      name: "View Restaurant",
                                      params: { id: item.restaurant.id },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(item.restaurant?.restaurant_name)
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  },
                },
                {
                  key: "restaurant",
                  fn: function ({ item }) {
                    return _c("td", {}, [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("div", { staticClass: "c-avatar mr-2" }, [
                            _c("img", {
                              staticClass: "c-avatar-img",
                              attrs: {
                                src: item.restaurant?.restaurant_logo_path,
                                alt: "",
                              },
                            }),
                          ]),
                          _c(
                            "CLink",
                            {
                              staticClass: "small text-muted",
                              attrs: {
                                to: {
                                  name: "View Restaurant",
                                  params: { id: item.restaurant?.id },
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.restaurant?.restaurant_name))]
                          ),
                        ],
                        1
                      ),
                    ])
                  },
                },
                {
                  key: "label",
                  fn: function ({ item }) {
                    return _c(
                      "td",
                      {},
                      [
                        _c("div", { staticClass: "clearfix" }, [
                          _c("div", { staticClass: "float-left" }, [
                            _c("strong", [_vm._v(_vm._s(item.labelObj.label))]),
                          ]),
                          _c("div", { staticClass: "float-right" }, [
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v(
                                _vm._s(_vm.moment(item.updated_at).calendar())
                              ),
                            ]),
                          ]),
                        ]),
                        _c("CProgress", {
                          staticClass: "progress-xs",
                          attrs: {
                            color: item.labelObj.color,
                            value: item.labelObj.progress,
                          },
                        }),
                      ],
                      1
                    )
                  },
                },
                {
                  key: "assigned_to",
                  fn: function ({ item }) {
                    return _c("td", {}, [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip",
                                value: { content: "#" + item.user.id },
                                expression: "{ content: '#' + item.user.id }",
                              },
                            ],
                            staticClass: "c-avatar mr-2",
                          },
                          [
                            _c("img", {
                              staticClass: "c-avatar-img",
                              attrs: {
                                src: item.user.image_icon || item.user.gravatar,
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _c("span", { staticClass: "small text-muted" }, [
                          _vm._v(_vm._s(item.user?.first_name)),
                        ]),
                      ]),
                    ])
                  },
                },
                {
                  key: "last_activity",
                  fn: function ({ item }) {
                    return _c("td", {}, [
                      _c("div", { staticClass: "d-flex" }, [
                        item.last_activity
                          ? _c("div", [
                              _c("div", { staticClass: "small text-muted" }, [
                                _vm._v(
                                  _vm._s(item.last_activity.event.slugToTitle())
                                ),
                              ]),
                              _c(
                                "strong",
                                {
                                  attrs: { role: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showTaskActivityLogs(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .moment(item.last_activity.created_at)
                                        .fromNow()
                                    )
                                  ),
                                ]
                              ),
                            ])
                          : _c("span", { staticClass: "small text-muted" }, [
                              _vm._v("-No activity-"),
                            ]),
                      ]),
                    ])
                  },
                },
                {
                  key: "actions",
                  fn: function ({ item, index }) {
                    return [
                      _c(
                        "td",
                        { staticClass: "text-left" },
                        [
                          _c(
                            "CButton",
                            {
                              directives: [
                                {
                                  name: "c-tooltip",
                                  rawName: "v-c-tooltip",
                                  value: {
                                    placement: "left",
                                    content: "Change Task Status",
                                  },
                                  expression:
                                    "{\n            placement: 'left',\n            content: 'Change Task Status'\n          }",
                                },
                              ],
                              attrs: {
                                size: "sm",
                                color: "dark",
                                variant: "ghost",
                                shape: "pill",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showTaskActivityLogs(item)
                                },
                              },
                            },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-fingerprint", size: "xl" },
                              }),
                            ],
                            1
                          ),
                          item.is_editable === true
                            ? _c(
                                "CButton",
                                {
                                  attrs: {
                                    size: "sm",
                                    color: "info",
                                    variant: "ghost",
                                    shape: "pill",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showEditTask(item)
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-align-left" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.is_deletable === true
                            ? [
                                item.deleting
                                  ? _c("CSpinner", {
                                      staticClass: "mx-2",
                                      attrs: { size: "sm", color: "danger" },
                                    })
                                  : _c(
                                      "CButton",
                                      {
                                        attrs: {
                                          size: "sm",
                                          color: "danger",
                                          variant: "ghost",
                                          shape: "pill",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteItem(item, index)
                                          },
                                        },
                                      },
                                      [
                                        _c("CIcon", {
                                          attrs: { name: "cil-trash" },
                                        }),
                                      ],
                                      1
                                    ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1543653329
            ),
          })
        : _c(
            "div",
            [
              _vm.loading
                ? _c("CSpinner")
                : _c("h6", [_vm._v("No task found!")]),
            ],
            1
          ),
      _c("EditTask", {
        attrs: { task: _vm.selectedTask },
        on: {
          "update:task": function ($event) {
            _vm.selectedTask = $event
          },
        },
      }),
      _c("TaskActivityLogs", {
        attrs: { task: _vm.selectedTask },
        on: {
          "update:task": function ($event) {
            _vm.selectedTask = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }